@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: center;
  flex: 0;
  padding-top: 140px;

  @media (--viewportMedium) {
    padding-top: 60px;
  }
}

.rootPractitioner {
  padding-top: 0;

  @media (--viewportMedium) {
    padding-top: 60px;
  }

  & .title {
    max-width: 390px;
  }
}

.signupPractitioner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 24px;

  border-bottom: 1px solid var(--marketplaceGreen);
  background-color: var(--marketplaceLightGreen);

  @media (--viewport1280) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.practitionerText {
  padding: 0;
  margin: 0 0 20px;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--fontWeightBold);

  @media (--viewport1280) {
    margin: 0;
  }
}

.practitionerLink {
  composes: buttonDefault from global;
  max-width: 232px;
  min-height: 30px;
  padding: 7px 10px;

  font-size: 14px;
  line-height: 1;
  font-weight: var(--fontWeightLight);

  background-color: var(--marketplaceGreen);
  color: var(--colorWhite);

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--marketplaceDarkGreen);
    color: var(--colorWhite);
  }
}


.title {
  color: var(--marketplaceSecondaryColor);
  text-align: center;
  margin: 0 auto;
  padding: 0;
  max-width: 320px;
}

.info {
  text-align: center;
  padding: 0;
  margin: 12px auto;
  font-weight: var(--fontWeightRegular);

  @media (--viewportCustom) {
    margin-bottom: 24px;
  }
}

.link {
  color: #26736F;
  font-weight: var(--fontWeightSemiBold);
}

.defaultUserFields {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  
  @media (--viewportMedium) {
    width: 70%;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.email,
.firstNameRoot,
.lastNameRoot,
.password {
  margin-top: 8px;
  margin-bottom: 16px;

  label {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 10px;
    line-height: 1.5;
    color: var(--colorDefaultGray);
    font-weight: var(--fontWeightRegular);
  }

  input {
    border-radius: var(--borderRadiusFull);
    padding: 3px 16px;

    @media (--viewportCustom) {
      padding: 7px 16px;
    }
  }
}

.passwordHolder {
  position: relative;

  & input {
    padding-right: 40px;
  }
}

.passwordIcon {
  position: absolute;
  top: 34px;
  right: 10px;
  cursor: pointer;
  z-index: 1;

  @media (--viewportMedium) {
    top: 28px;
  }
}

.bottomWrapper {
  margin-top: 12px;
}

.button {
  width: 100px;
  margin: 12px auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transitionStyle);
  cursor: pointer;
  font-weight: var(--fontWeightLight);
  text-align: center;
  text-decoration: none;
  border-width: 1px;
  border-radius: var(--borderRadiusFull);
  background-color: var(--marketplaceColor);
  color: var(--marketplaceSecondaryColor);

  @media (--viewportCustom) {
    margin: 32px auto;
  }
}

.button:focus,
.button:hover {
  outline: none;
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--marketplaceColorDark);
}

.button:disabled {
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  background-color: var(--colorGrey100);
  color: var(--colorWhite);
}

.divider {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 18px;
}

.line {
  width: 100%;
  border-top: 1px solid var(--colorBlack);
  position: relative;
  top: 12px;
}

.orText {
  white-space: nowrap;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin-top: 18px;
  justify-content: center;
}

.green {
  color: var(--marketplaceGreen);
}
