@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: center;
  flex: 0;

  @media (--viewportMedium) {
    /* justify-content: space-between; */
  }
}

.title {
  color: var(--marketplaceSecondaryColor);
  text-align: center;
  margin: 0;
  padding: 0;
}

.info {
  text-align: center;
  padding: 0;
  margin: 12px auto;
  font-weight: var(--fontWeightRegular);

  @media (--viewportCustom) {
    margin-bottom: 24px;
  }
}

.link {
  color: #26736F;
  font-weight: var(--fontWeightSemiBold);
}

.email,
.password {
  /* composes: marketplaceModalPasswordMargins from global; */
  margin-top: 8px;

  label {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 10px;
    line-height: 1.5;
    color: var(--colorDefaultGray);
    font-weight: var(--fontWeightRegular);
  }

  input {
    border-radius: var(--borderRadiusFull);
    padding: 3px 16px;

    @media (--viewportCustom) {
      padding: 7px 16px;
    }
  }
}

.email {
  margin-bottom: 16px;
}

.passwordHolder {
  position: relative;

  & input {
    padding-right: 40px;
  }
}

.passwordIcon {
  position: absolute;
  top: 34px;
  right: 10px;
  cursor: pointer;
  z-index: 1;

  @media (--viewportMedium) {
    top: 28px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  text-align: center;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  font-weight: var(--fontWeightSemiBold);
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
  color: var(--colorDefaultGray);
}

.bottomWrapper {
  margin-top: 24px;
}

.button {
  width: 100px;
  margin: 32px auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transitionStyle);
  cursor: pointer;
  font-weight: var(--fontWeightLight);
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--marketplaceSecondaryColor);
  border-radius: var(--borderRadiusFull);
  background-color: var(--marketplaceColor);
  color: var(--marketplaceSecondaryColor);
}

.button:focus,
.button:hover {
  outline: none;
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--marketplaceColorDark);
}

.button:disabled {
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  background-color: var(--colorGrey100);
  color: var(--colorWhite);
}

.divider {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 18px;
}

.line {
  width: 100%;
  border-top: 1px solid var(--colorBlack);
  position: relative;
  top: 12px;
}

.orText {
  white-space: nowrap;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin-top: 18px;
  justify-content: center;
}

.defaultUserFields {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  
  @media (--viewportMedium) {
    width: 70%;
  }
}
