@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  composes: marketplaceModalHelperLink from global;
  padding-bottom: 6px;
  text-align: center;

  font-size: 14px;
  color: var(--marketplaceSecondaryColor);

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: #26736F;
  font-weight: var(--fontWeightSemiBold);

  &:hover {
    /* color: var(--marketplaceColorDark); */
    text-decoration: underline;
    cursor: pointer;
  }
}